import { Button, ButtonGroupProps, Link } from "@chakra-ui/react";
import * as React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

export const SocialMediaLinks = (props: ButtonGroupProps) => (
  <Button
    colorScheme="red"
    textColor="#FFF"
    leftIcon={<FaExternalLinkAlt />}
    variant="solid"
    onClick={() => console.log("aperto")}
  >
    <Link href="https://xlung.net/" isExternal>
      Visit Website
    </Link>
  </Button>
);
