import {
  As,
  Flex,
  FlexProps,
  Heading,
  OmitCommonProps,
  Link,
  Button,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { Logo } from "./Logo";

const Header = (
  props: JSX.IntrinsicAttributes &
    OmitCommonProps<
      React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
      >,
      keyof FlexProps
    > &
    FlexProps &
    OmitCommonProps<any, keyof FlexProps> & { as?: As<any> | undefined }
) => {
  return (
    <Flex
      width="100%"
      as="nav"
      role="contentinfo"
      mx="auto"
      maxW="7xl"
      py="8"
      px={{ base: "4", md: "8" }}
      bg="#D03230"
      color="white"
      {...props}
    >
      <Flex align="center" justify="space-between">
        <Logo />
        <Heading as="h1" size="lg" letterSpacing={"tighter"} ml={5}>
          Xlung App
        </Heading>
        <HStack>
          <Button colorScheme="red" textColor="#FFF" variant="solid" ml={35}>
            <Link href="/">Terms of use</Link>
          </Button>
          <Button colorScheme="red" textColor="#FFF" variant="solid" ml={3}>
            <Link href="/privacy">Privacy Policy</Link>
          </Button>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default Header;
