import { ChakraProvider, theme } from "@chakra-ui/react";
import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <Switch>
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/" component={TermsOfUse} />
      </Switch>
    </Router>
  </ChakraProvider>
);
