import {
  chakra,
  HTMLChakraProps,
  useColorModeValue
} from "@chakra-ui/react";
import * as React from "react";

export const Logo = (props: HTMLChakraProps<"svg">) => {
  return (
    <chakra.svg
      color={useColorModeValue("blue.500", "blue.300")}
      width="50"
      height="50"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M173.909 33.5348C158.574 24.5616 144.567 20.1041 122.416 20C94.4655 20.3337 67.2638 29.0123 44.355 44.9051C38.5884 48.9769 32.4607 53.6916 35.6048 55.9265C37.7152 57.4358 41.5473 56.597 46.3359 52.7272C80.3774 25.2076 130.022 18.0866 162.166 34.0063C194.007 49.7943 204.044 82.5277 172.483 95.9278C163.281 99.4885 153.225 100.278 143.573 98.1964C131.154 95.8544 118.603 86.8077 119.127 73.2576C119.568 61.8505 131.993 53.6641 142.268 51.2853C155.967 48.1289 160.901 51.4965 161.888 47.4401C162.545 44.7307 161.623 42.9213 155.828 42.0702C136.554 39.2414 111.543 45.8114 105.227 66.0478C104.807 67.3903 104.485 68.7614 104.265 70.1502C98.0983 67.2087 91.4921 65.2784 84.7031 64.4344C66.0326 62.1811 47.1283 65.9773 30.81 75.2567C14.6115 84.4412 -5.28946 103.729 1.29175 130.397C3.59184 139.515 7.94837 147.996 14.0328 155.2C20.1173 162.404 27.7708 168.143 36.4162 171.984C71.5901 188.59 121.71 181.845 159.028 155.146C171.034 146.78 181.267 136.154 189.145 123.873C189.614 123.068 192.397 119.281 189.284 118.16C187.804 117.919 186.286 118.053 184.873 118.55C183.459 119.047 182.196 119.892 181.2 121.004C154.897 160.43 89.8712 182.984 44.0681 161.07C13.0318 146.259 1.44294 109.509 33.806 87.2363C42.3139 80.8112 52.4011 76.7645 63.0188 75.5169L63.9444 75.4006C71.7284 74.3085 79.646 74.5956 87.3288 76.2486C98.4981 78.6978 110.306 87.025 110.741 99.4822C111.759 115.019 93.8669 127.118 81.0469 131.031C76.2522 132.497 70.6953 132.904 64.1881 134.313C59.5261 135.32 50.998 138.106 53.275 143.825C54.793 147.63 64.1912 147.747 70.467 147.107C92.031 144.909 121.543 132.283 125.696 108.394C125.972 106.812 126.153 105.215 126.236 103.612C132.918 106.899 140.088 109.102 147.473 110.136C168.67 113.152 192.255 102.577 198.074 85.9168C205.806 63.7823 188.734 42.208 173.909 33.5348Z"
        fill="#FFF"
      />
    </chakra.svg>
  );
};
