import { Box, Stack } from "@chakra-ui/layout";
import React from "react";
import { Copyright } from "./Copyright";
import { Logo } from "./Logo";
import { SocialMediaLinks } from "./SocialMediaLinks";

export default function Footer() {
  return (
    <Box
      width="100%"
      as="footer"
      role="contentinfo"
      mx="auto"
      maxW="7xl"
      py="8"
      px={{ base: "4", md: "8" }}
      bg="#D03230"
    >
      <Stack>
        <Stack
          direction="row"
          spacing="4"
          align="center"
          justify="space-between"
        >
          <Logo />
          <SocialMediaLinks />
        </Stack>
        <Copyright alignSelf={{ base: "center", sm: "start" }} />
      </Stack>
    </Box>
  );
}
