import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import * as React from "react";
import { withRouter } from "react-router";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const TermsOfUse = () => {
  return (
    <VStack spacing={12}>
      <Header />
      <Box fontSize="xl" p={12} width="100%">
        <Box width="100%">
          <Heading>Terms of Use - Xlung App</Heading>
          <Text marginTop="5" textAlign="justify">
            In order to sign the commitment with the USERS to the security and
            privacy of information passed and received through its website
            www.xlung.net and applications, publishes its "Privacy Policy" based
            on the principles of its commitment to transparency and respect with
            the USERS. A USER is any person or entity that interacts, visits or
            uses the websites www.xlung.net and other services offered by XLUNG
            electronically. XLUNG reserves the right to update, revise,
            supplement or otherwise amend this Privacy Policy at any time and
            from time to time, and to impose new or additional rules, policies,
            terms or conditions on the use of the services offered through the
            channels mentioned above, or interactions with the system owned by
            XLUNG, hereinafter referred to as "XLUNG SYSTEM". The updates,
            revisions, supplements, modifications, rules, policies, additional
            terms and conditions (jointly referred to herein as "Additional
            Terms") shall be made available on the XLUNG websites and
            applications. Thus, we recommend that the USER periodically access
            the website www.xlung.net in order to be aware of the mentioned
            changes
          </Text>
        </Box>

        <Box width="100%" my={5}>
          <Heading>Information Security</Heading>
          <Text marginTop="5" textAlign="justify">
            XLUNG guarantees the security and privacy of identity to all its
            USERS, understood as any person who uses the services and systems of
            XLUNG, directly or indirectly. XLUNG advises that the use of some
            services provided in the websites and applications may only be
            carried out upon registration or enrollment by the USER. Through
            this policy, XLUNG ensures the privacy and security of all data
            registered in its database by advanced encryption systems or the
            like. Such data shall only be used and disclosed by XLUNG for the
            performance of services related to its purpose. The USER registered
            on the XLUNG system shall have access data to the services available
            on the XLUNG channels. The USER shall be solely responsible for
            maintaining the confidentiality and security of its data, excluding
            XLUNG from any liability for the improper use of such information.
            XLUNG shall not disclose information of its data to anyone, nor use
            data of another USER. The USER is fully responsible for all
            activities occurring in its access account and agrees to immediately
            notify XLUNG regarding any unauthorized use or any other breach of
            security. XLUNG shall not be liable for any damages incurred as a
            result of the unauthorized use of the USER data. XLUNG reserves the
            right to suspend the access of the USER, and even refuse future
            registration, if it suspects that the USER has used false
            information when registering. All information regarding the
            registration to access the XLUNG channels, when required, are for
            the exclusive use of the USER and shall not be disclosed to third
            parties. The data shall always be protected and, at the end of the
            operations, care shall be taken to exit the websites and
            applications appropriately (logout). The registration will only be
            effective after the USER's previous analysis and agreement to this
            privacy policy. All information about USER browsing on XLUNG
            channels is stored (IP address, cookies and pages accessed). XLUNG
            may refuse or prevent access to USERS who fail to comply with the
            conditions set out in this Term.
          </Text>
        </Box>

        <Box width="100%" my={5}>
          <Heading>Of Information Collection</Heading>
          <Text marginTop="5" textAlign="justify">
            By using the services provided by XLUNG, the USER identification may
            be required. The identification may result from a simple
            registration form containing required and optional fields. In
            addition to the registration information, XLUNG may also store data
            on transactions and other types of interaction with the websites,
            applications and the XLUNG SYSTEM. The personal data registered and
            captured by XLUNG may be subject to automated treatment, and
            incorporated to the corresponding electronic records, of which XLUNG
            shall be the owner and responsible. The data obtained and used by
            XLUNG, as well as by the partners hired by XLUNG, are part of this
            "Privacy Policy". In situations requiring authentication of the
            actions performed, such as Membership, Regulations and Terms and/or
            Electronic Agreements, the IP address of the USER's computer may be
            extracted. XLUNG may disclose the respective information to the
            participants of the "XLUNG SYSTEM", similarly to the information
            already made available by data survey and market measurement
            companies, even if such information is more detailed, not
            characterizing such act as breach of secrecy or confidentiality.
            XLUNG may correct any inaccuracies in the USERS' data. If there is
            any change in the USER's registration information, it is incumbent
            upon the USER to notify XLUNG in advance.
          </Text>
        </Box>

        <Box width="100%" my={5}>
          <Heading>Use of Information</Heading>
          <Text marginTop="5" textAlign="justify">
            XLUNG may sell consolidated statistical reports of the data provided
            by USERS, always in strict compliance with ethics, information
            security and individual confidentiality. XLUNG is committed to: (i)
            Not disclose, without authorization, the USERS' data for purposes
            other than for the performance of its services, subject to the
            exceptions contained in this "Privacy Policy"; (ii) Adopt as a
            policy the commitment not to disclose information about the accesses
            made by its USERS, unless it is required to do so by court order or
            in strict compliance with legal or contractual standard; (iii) Use
            technical resources with the objective of constantly improving its
            services and to adapt them increasingly to the needs of the USERS;
            and (iv) Use cookies to obtain only information of a technical
            nature or navigation of the Portal. Even so, cookies may be disabled
            by the USER himself through the configuration of his own browsers,
            better known as browsers. The USER agrees to: (i) comply with the
            rules of use, as described in more detail below and not violate any
            security components; (ii) not attempt nor assist third parties to
            circumvent, reverse engineer, disaggregate or otherwise interfere
            with any security components regarding these rules of use, for any
            purpose; (iii) not make any attempt to access the registration of
            third parties, unless duly authorized; (iv) not modify the website
            in any way or form or use modified versions of the XLUNG SYSTEM, for
            any purposes, including for obtaining unauthorized access to the
            services offered by the websites and applications; (v) keep the
            personal and registration information always updated and; (vi)
            respond, civilly and criminally, for the accuracy of the
            registration information; The USER is hereby aware that the
            information provided through the websites and applications contain a
            security structure, using technology that protects digital
            information and limits the use of confidential information,
            subjecting them to certain rules of use established by XLUNG and its
            representatives. XLUNG warns that violations of network or system
            security may result in civil and/or criminal liability. By
            downloading the application and accessing the systems provided by
            XLUNG, the USER authorizes XLUNG and its partners to make telephone
            contact, and send promotional and institutional information
            materials through the email address informed in the registration.
          </Text>
        </Box>

        <Box width="100%" my={5}>
          <Heading>Confidentiality of Information</Heading>
          <Text marginTop="5" textAlign="justify">
            All data obtained through the XLUNG channels are confidential and
            may only be used by the USER and its agents to comply with the
            agreement between the parties. XLUNG is committed not to sell, rent
            or disclose the information of the USERS, except in cases where
            there is an official request from any public authority and in the
            cases provided in the "Privacy Policy" or in Agreements with the
            USERS. XLUNG undertakes to comply with all standards applicable to
            the processing of personal data used or obtained in the performance
            of its activities with regard to privacy, confidentiality, security
            and protection of personal data, and shall also maintain security
            tools in accordance with the best available technology to prevent
            the loss or any undue access to such data. By downloading the
            application or accessing the systems provided by XLUNG, the USER
            authorizes and agrees that XLUNG and all partner companies
            participating in SISTEMA XLUNG may exchange registration information
            about them at any time.
          </Text>
        </Box>

        <Box width="100%" my={5}>
          <Heading>Intellectual Property</Heading>
          <Text marginTop="5" textAlign="justify">
            The copyrights of all content available on XLUNG channels, including
            text, pages, illustrations, graphics, reports, technical data and
            materials, are protected by Law 9610/1998. The unauthorized
            reproduction, adaptation, modification or use of the content
            provided by XLUNG, in part or in whole, is expressly prohibited. The
            use of the XLUNG logo is exclusive to the company, and its use for
            any purpose by third parties is prohibited, unless expressly
            authorized by XLUNG.
          </Text>
        </Box>

        <Box width="100%" my={5}>
          <Heading>Of Applicable Law</Heading>
          <Text marginTop="5" textAlign="justify">
            This Privacy Policy shall be interpreted and governed in accordance
            with the Brazilian laws.
          </Text>
        </Box>

        <Box width="100%" my={5}>
          <Heading>Contact Us</Heading>
          <Text marginTop="5" textAlign="justify">
            This Privacy Policy applies to all products, services, websites and
            applications offered by XLUNG. If you have any questions about this
            "Privacy Policy", please feel free to contact us via the following
            emails: contact@xlung.net or support@xlung.net
          </Text>
        </Box>
      </Box>
      <Footer />
    </VStack>
  );
};

export default withRouter(TermsOfUse);
